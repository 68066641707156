import zeropad from 'zeropad'

function RecordingDateTime(props) {
	
	const { recording } = props

	const locationItems = []
	if (recording.recorderLocation) {
		locationItems.push(recording.recorderLocation)
	}

	if (props.country && recording.recorderCountry) {
		locationItems.push(recording.recorderCountry)
	}

	if (locationItems.length == 0) {
		locationItems.push(recording.groundStationLat)
		locationItems.push(recording.groundStationLng)
	}

	//const locations = ['Port na Ba, Mull', 'Bangkok, Thailand', 'Kanagawa 243-0036, Japan']
	//locations[Math.floor(Math.random() * locations.length)]
	const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
	const location = locationItems.join(', ')

	let time = '00:00:00'
	if (!isNaN(recording.timeBeganRecordingValues.h) && !isNaN(recording.timeBeganRecordingValues.i) && !isNaN(recording.timeBeganRecordingValues.s)) {
		time = `${zeropad(recording.timeBeganRecordingValues.h)}:${zeropad(recording.timeBeganRecordingValues.i)}:${zeropad(recording.timeBeganRecordingValues.s)}`		
	}
	const date = `${recording.dateSatellitePassValues.d} ${monthNames[recording.dateSatellitePassValues.m]}. ${recording.dateSatellitePassValues.y}`

	return (
		<div>
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10"><line x1="10" y1="5" y2="5"/><line x1="5" x2="5" y2="10"/></svg>
			<span className="location">{location}</span><br/>


			<span className="time">
				at <span>{time}</span> UTC
				on <span>{date}</span>
			</span>
		</div>
	)
}

export default RecordingDateTime