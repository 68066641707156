import RecordingDateTime from './RecordingDateTime'

function RecordingsList(props) {

	const { currentRecordingIdx } = props

	return (
		<div className="recording__list">
			{props.recordings.map((recording, rowIdx) => (

				<RecordingsListItem 
					active={(rowIdx === currentRecordingIdx)}
					key={`recordings_${rowIdx}`} 
					recording={recording} 
					rowIdx={rowIdx} 
					onRecordingSelect={props.onRecordingSelect}
				/>
			))}
		</div>
	)
}

function RecordingsListItem(props) {

	const classNames = []
	
	if (props.recording.cop26NowcaseCoreContributor) {
		classNames.push('core-contributor')
	}

	if (props.active) {
		classNames.push('active')	
	}

	return (
		<div className={classNames.join(' ')}>
			<div onClick={e => props.onRecordingSelect(props.rowIdx)}>

				<RecordingDateTime recording={props.recording} country={false} />
			</div>
		</div>
	)
}

export default RecordingsList