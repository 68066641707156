import map from './images/IsleofMull-2021.jpg'

export default function About(props) {

	return (
		<div className="about">
			<div className="about__inner">
				<div className="about__close" onClick={props.onClose}>Close</div>
				
				<h1>When I image the earth I imagine another</h1>
				<p>What would it mean to collectively image, and in doing so, reimagine the planet? To see its details and patterns from multiple perspectives and many situated positions? If we could each take a photo of our home from space, could we build a patchwork, an impossible view, another whole earth?</p>

				<p>On the first day of the COP26 climate conference in Glasgow, a network of people operating DIY satellite ground stations around the world will capture a collective snapshot of the Earth and its weather systems: a ‘nowcast’ for an undecided future. Tuning into transmissions from three orbiting National Oceanic and Atmospheric Administration (NOAA) satellites, members of the network will collect imagery and submit field notes from their geographical locations. Combined, these contributions will generate a polyperspectival (from many angles) image of the earth. Led by open-weather (Sophie Dyer and Sasha Engelmann) with <a href="https://rectangle.design/" target="_blank">Rectangle</a> (Lizzie Malcolm and Daniel Powers), the artwork is a feminist experiment in imaging and reimagining the planet in an era of climate crisis.</p>
				<p><em>‘When I image the earth I imagine another’ is supported by <a target="_blank" href="https://thephotographersgallery.org.uk/whats-on/when-i-image-earth-i-imagine-another">The Photographers’ Gallery</a> (London, UK) in partnership with <a target="_blank" href="https://tabakalera.eus/en">CCIC Tabakalera</a> (Donostia / San Sebastián, Spain).</em></p>

				<p>Open-weather (Sophie Dyer and Sasha Engelmann) extend our warmest thanks to <a target="_blank" href="https://rectangle.design">Rectangle</a> (Lizzie Malcolm and Daniel Powers) for collaborating on the nowcast for COP26.</p>

				<p>Open-weather thank the nowcast contributors, in particular:</p>

				<strong>Ground stations</strong><br/>
				Alison Scott (Glasgow, UK)<br/>
				Ankit Sharma (Mumbai, India)<br/>
				Aouefa Amoussouvi (Bucharest, Romania)<br/>
				Barfrost (Kirkenes, Norway)<br/>
				Bill Liles (Reston, USA)<br/>
				Carl Reineman (Jefferson, USA)<br/>
				Catherine Fletcher (Norfolk, USA)<br/>
				Cedrick Lukunku Tshimbalanga (Kinshasa, DR Congo)<br/>
				Chonmapat Torasa (Bangkok, Thailand)<br/>
				Dey Kim (Seoul, South Korea)<br/>
				Florent Leon Noel (Venice, Italy)<br/>
				George Ridgeway (Melbury Abbas, U.K.)<br/>
				Jasmin Schädler (Stuttgart, Germany)<br/>
				Joaquin Ezcurra (Buenos Aires, Argentina)<br/>
				Ketsia Kinsumba Muanakiese (Kinshasa, DR Congo)<br/>
				L Paul Verhage (Homedale, USA)<br/>
				Natasha Honey (Newcastle, Australia)<br/>
				Olivia Berkowicz (Paris, France)<br/>
				Pablo Cattaneo (Mar Del Plata, Argentina)<br/>
				Sofia Caferri (San Vittoria, Italy)<br/>
				Steve Engelmann (Los Angeles, USA)<br/>
				Sybille Neumeyer (Berlin, Germany)<br/>
				WXVids / Zefie (Albany, USA)<br/>
				Yoshi Matsuoka (Atsugi, Japan)<br/>
				Zack Wettstein (Seattle, USA)<br/>
				<br/>
				<strong>Special thanks</strong><br/>
				A. and J. Powers<br/>
				Alexander Barth<br/>
				Anna Pasco Bolta<br/>
				Arieh Frosh<br/>
				Eugenia Morpurgo<br/>
				Grayson Earle<br/>
				Jon Uriarte<br/>
				Martin Bernardi<br/>
				Nicola Locatelli<br/>
				RTL-SDR blog<br/>
				Sabina Ahn<br/>
				Winkler Spezialantennen<br/>

			</div>
		</div>
	)
}
