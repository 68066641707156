export default function dragScroller() {

	let dragging = false
	let scrollX = 0
	let scrollY = 0
	let mouseX = 0
	let mouseY = 0

	window.addEventListener('mousedown', e => {
		dragging = true
		scrollX = window.scrollX
		scrollY = window.scrollY
		mouseX = e.clientX
		mouseY = e.clientY

	})

	window.addEventListener('mousemove', e => {
		if (dragging) {
			const dx = e.clientX - mouseX
			const dy = e.clientY - mouseY
			window.scrollTo(scrollX - dx, scrollY - dy)
		}
	})

	window.addEventListener('mouseup', e => {
		dragging = false
	})
}