import React, { useEffect, useState } from 'react'
// import PublicGoogleSheetsParser from 'public-google-sheets-parser'
import driveURL from 'drive-url'

import * as d3 from 'd3'
import hasTouch from 'has-touch'

import RecordingDetails from './RecordingDetails'
import RecordingsList from './RecordingsList'
import RecordingsMap from './RecordingsMap'
import About from './About'
import './App.css'

import dragScroller from './dragScroller'
import rowData from './rowdata.json'


// const spreadsheetId = '1nrPsDTK1apCcI_CT2HIUeE7_zXIHh4MeBG2IpE4TMcc'
// const parser = new PublicGoogleSheetsParser(spreadsheetId)

function App() {

	const [aboutOpen, setAboutOpen] = useState(false)
	const [recordings, setRecordings] = useState([])
	const [currentRecordingIdx, setCurrentRecordingIdx] = useState(undefined)

	const projection = d3.geoEqualEarth()
	const mapWidth = 10912
	const mapHeight = 5311
	const wholeEarth = {
		'type': 'Polygon',
		'coordinates': [
			[[-180,-90],[180,-90],[180,90],[-180,90],[-180,-90]]
		]
	}
	projection.fitSize([mapWidth, mapHeight], wholeEarth)

	useEffect(() => {
		if (!hasTouch) {
			dragScroller()
		}
		console.log(rowData)
		setRecordings(rowData)
		// parser.parse().then((rows) => {

		// 	const rowData = []
		// 	rows.forEach((row,i) => {			
		// 		if(i >= 63 && i <= 101){
		// 			const rowItem = getRowItem(row)
		// 			rowItem.idx = rowData.length
		// 			const pixels = projection([rowItem.groundStationLng, rowItem.groundStationLat])
		// 			rowItem.pixels = pixels
		// 			rowData.push(rowItem)
		// 		}				
		// 	})
		// })		

		document.addEventListener('scroll', e => {
			console.log(window.scrollX, window.scrollY)
		})

		window.scrollTo(5200, 400)
	}, [])

	const handleMapLoaded = e => {
		window.scrollTo(5200, 400)
	}



	return (
		<div className="App">
			<header>
				<div className="about__toggle" onClick={e => setAboutOpen(!aboutOpen)}>About</div>
				<div>When I image the earth, I imagine another</div>
				<div className="external"><span>Go to</span> <a href="https://open-weather.community" target="_blank">open-weather.community</a><span>↗</span></div>
			</header>
			
			{aboutOpen && <About onClose={e => setAboutOpen(false)}/>}


			<RecordingsList recordings={recordings} currentRecordingIdx={currentRecordingIdx} onRecordingSelect={rowIdx => {			
				setCurrentRecordingIdx(rowIdx)
				setAboutOpen(false)
			}} />
			<RecordingDetails recordings={recordings} currentRecordingIdx={currentRecordingIdx} onClose={e => setCurrentRecordingIdx(undefined)} />
			{recordings.length > 0 && (
				<RecordingsMap 
					recordings={recordings} 
					currentRecordingIdx={currentRecordingIdx} 
					onRecordingSelect={rowIdx => {
						setCurrentRecordingIdx(rowIdx)
						setAboutOpen(false)						
					}}
					mapWidth={mapWidth}
					mapHeight={mapHeight}
					wholeEarth={wholeEarth}
					onMapLoaded={handleMapLoaded}
				/>
			)}
		</div>
	)
}

function getRowItem(row) {

	const timestampYMDHMS = row['Timestamp'].replace('Date(', '').replace(')', '').split(',')
	const timestampValues = {
		y: parseInt(timestampYMDHMS[0]),
		m: parseInt(timestampYMDHMS[1]),
		d: parseInt(timestampYMDHMS[2]),
		h: parseInt(timestampYMDHMS[3]),
		i: parseInt(timestampYMDHMS[4]),
		s: parseInt(timestampYMDHMS[5]),
	}
	const timestamp = new Date(Date.UTC(timestampValues.y, timestampValues.m, timestampValues.d, timestampValues.h, timestampValues.i, timestampValues.s))

	const dateSatellitePassYMD = row['Enter the date of your recording in the local time'].replace('Date(', '').replace(')', '').split(',')
	const dateSatellitePassValues = {
		y: parseInt(dateSatellitePassYMD[0]),
		m: parseInt(dateSatellitePassYMD[1]),
		d: parseInt(dateSatellitePassYMD[2]),					
	}
	const dateSatellitePass = new Date(Date.UTC(dateSatellitePassValues.y, dateSatellitePassValues.m, dateSatellitePassValues.d, 0, 0, 0, 0))
	const timeBeganRecordingHMS = row['Now, enter the exact time your recording started in UTC (Coordinated Universal Time)'].replace('Date(', '').replace(')', '').split(',')


	const timeBeganRecordingValues = {
		h: 0,
		i: 0,
		s: 0,
	}

	if (timeBeganRecordingHMS) {
		timeBeganRecordingValues.h = parseInt(timeBeganRecordingHMS[3])
		timeBeganRecordingValues.i = parseInt(timeBeganRecordingHMS[4])
		timeBeganRecordingValues.s = parseInt(timeBeganRecordingHMS[5])
	}

	const antennaType = row['What type antenna did you use? (optional)']
	const dongleMake = row['Which dongle did you used? (optional)']
	const sdrSoftware = row['Which SDR software did you used? (optional)']
	const satelliteDecodingSoftware = row['Which satellite decoding software did you used? (optional)']
	const satelliteReceived = row['Which satellite did you received?']
	const entryCredit = row['Tell us your name(s)']
	const radioCallsign = row['Enter your a radio callsign or signs, if applicable']

	const groundStationLatLng = row['Enter the latitude and longitude of your satellite ground station'].split(',')
	const groundStationLat = parseFloat(groundStationLatLng[0].trim())
	const groundStationLng = parseFloat(groundStationLatLng[1].trim())

	const recorderLocation = row['Describe your location']
	const recorderCountry = row['Which country or territory are you in?']
	const audioRecordingURL = row['Upload the audio recording of the satellite transmission']
	const rawImageURL = row['Upload the raw image file (optional)']
	const imageEnhancementsURL = row['Upload any image enhancements you would like to archive']
	
	const additionalImagesURLValue = row['Upload additional images. For example, photos of your ground station or you receiving the satellite transmission. (optional)']
	const additionalImages = []
	if (additionalImagesURLValue) {

		const additionalImagesURLValues = additionalImagesURLValue.split(',')
		
		if (additionalImagesURLValues) {
			additionalImagesURLValues.forEach(additionalImagesURLVale => {
				const additionalImageURL = additionalImagesURLValue.trim()
				if (additionalImageURL) {
					additionalImages.push(additionalImageURL)
				}
			})
		}
	}
	
	const recorderWeatherDescriptionValue = row['What was your experience of the weather while receiving the satellite transmission?']
	const recorderWeatherDescription = (recorderWeatherDescriptionValue) ? recorderWeatherDescriptionValue : false
	
	const recorderClimateChangeLocateValue = row['Where do you locate climate change in your local community or environment? (COP26 nowcast only)']
	const recorderClimateChangeLocate = (recorderClimateChangeLocateValue) ? recorderClimateChangeLocateValue : false


	const cop26NowcaseCoreContributorValue = row['CO26 nowcast core contributer']
	const cop26NowcaseCoreContributor = (cop26NowcaseCoreContributorValue) ? true : false

	const originalWAVFilename = row['Original WAV filename']
	const barthsWAVFIlename = row['Barths format WAV filename']
	const decodedWAVFilename = (barthsWAVFIlename) ? barthsWAVFIlename.replace('.wav', '.jpg') : false
	const additionalImageFilename = (row['Additional image filename']) ? row['Additional image filename'] : false

	return {
		// timestampValues,
		// timestamp,
		// dateSatellitePassValues,
		// dateSatellitePass,
		// timeBeganRecordingValues,
		// antennaType,
		// dongleMake,
		// sdrSoftware,
		// satelliteDecodingSoftware,
		// satelliteReceived,
		// entryCredit,
		// radioCallsign,
		// groundStationLat,
		// groundStationLng,
		// recorderLocation,
		// recorderCountry,
		// audioRecordingURL,
		// rawImageURL,
		// imageEnhancementsURL,
		// additionalImages,
		// recorderWeatherDescription,
		recorderClimateChangeLocate,
		// cop26NowcaseCoreContributor,
		// originalWAVFilename,
		barthsWAVFIlename
		// decodedWAVFilename,		
		// additionalImageFilename,
	}
}

function getDriveImage(url) {
	const downloadURL = driveURL(url)
	const viewURL = downloadURL.replace('?export=download', '?export=view')	
	const urlParams = new URLSearchParams(viewURL)
	const fileID = urlParams.get('id')
	// const thumbURL = `https://drive.google.com/thumbnail?sz=w320&id=${fileID}`
	
	return {
		download: downloadURL,
		view: viewURL,
	}
}


export default App
