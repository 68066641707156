import { useEffect, useRef } from 'react'
//import Panzoom from '@panzoom/panzoom'
import * as d3 from 'd3'
import { feature } from 'topojson-client'

import map from './images/map-2200.gif'
import animateScrollTo from 'animated-scroll-to'
// import ScrollContainer from 'react-indiana-drag-scroll'

import hasTouch from 'has-touch'

function RecordingsMap(props) {

	const { recordings, currentRecordingIdx } = props
	const recording = recordings[currentRecordingIdx]

	const mapContainer = useRef(null)
	const vectorContainer = useRef(null)

	let panzoom



	useEffect(() => {
	
		const projection = d3.geoEqualEarth()
		const path = d3.geoPath().projection(projection)
		const svg = d3.select(vectorContainer.current).append('svg').attr('width', props.mapWidth).attr('height', props.mapHeight)

		const points = []

		recordings.forEach(r=>{
			points.push({
				recordingIdx: r.idx,
				cop26NowcaseCoreContributor: r.cop26NowcaseCoreContributor,
				point: [r.groundStationLng,r.groundStationLat]
			})
		})
		
		projection.fitSize([props.mapWidth, props.mapHeight], props.wholeEarth)
		svg.selectAll('polygon')
			.data(points).enter()
			.append('polygon')
			.attr('points', '18 7.5 10.5 7.5 10.5 0 7.5 0 7.5 7.5 0 7.5 0 10.5 7.5 10.5 7.5 18 10.5 18 10.5 10.5 18 10.5 18 7.5')
			.attr('transform', function(d) { return 'translate('+ (projection(d.point)[0] - 46) +',' + (projection(d.point)[1] - 10) + ')' })
			.attr('fill', function(d){
				if(d.cop26NowcaseCoreContributor){
					return '#00aa6e'
				} else {
					return '#FFF'
				}
			})
			.on('click', function(d, i){
				props.onRecordingSelect(i.recordingIdx)
				// set recording in state
			})

		const pixels = projection([recordings[0].groundStationLng, recordings[0].groundStationLat])

		const pathGenerator = d3.geoPath().projection(projection)
		d3.json('https://unpkg.com/world-atlas@2.0.2/land-50m.json')
			.then(data => {
				const countries = feature(data, data.objects.land)
				
				svg.selectAll('path.country').data(countries.features)
					.enter().append('path')
					.attr('class', 'country')
					.attr('d', pathGenerator)
					.attr('stroke', '#FFF')
					.attr('stroke-width', 1)
					.attr('fill', 'transparent')
					
			})

	}, [])


	//5677.859859210584,786.8257729822515

	useEffect(() => {
		if (recording) {
			let x, y
			if(hasTouch){
				x = recording.pixels[0] - 10
				y = recording.pixels[1] - 85
			} else {
				x = recording.pixels[0] - (window.innerWidth / 2) - 155 + (window.innerWidth * 0.25)
				y = recording.pixels[1] - (window.innerHeight / 2) + (window.innerHeight * 0.25)	
			}
			
			animateScrollTo([x, y], { speed: 100 })
		}
	})

	return (
		<div id="map-container" ref={mapContainer}>
			<div id="vectormap" ref={vectorContainer}>

			</div>
			<div id="rastermap">
				<img src={map} onLoad={props.onMapLoaded}/>
			</div>

		</div>
	)
}

export default RecordingsMap