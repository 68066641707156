import RecordingDateTime from './RecordingDateTime'
import { useEffect } from 'react'

function importImages(r) {
	let images = {}
	r.keys().map((item, index) => { images[item.replace('./', '')] = r(item) })
	return images
}


const decodedImages = importImages(require.context('./images/decoded', false, /\.(png|jpe?g|svg)$/))
const additionalImages = importImages(require.context('./images/additional', false, /\.(JPG|png|jpe?g|svg)$/))


function RecordingsDetails(props) {

	const { recordings, currentRecordingIdx } = props

	if (!recordings || recordings.length === 0) {
		return null
	}

	const recording = recordings[currentRecordingIdx]
	if (!recording) {
		return null
	}
	const styles = {
		top: recording.pixels[1],
		left: recording.pixels[0]
	}

	useEffect(() => {
		window.addEventListener('mouseup', e => {
			if (!e.target.closest('.recording__details')) {
				props.onClose()
			}
		})
	}, [])

	const classNames = ['recording__details']
	if (recording.cop26NowcaseCoreContributor) {
		classNames.push('core-contributor')
	}

	return (
		<div className={classNames.join(' ')} style={styles}>
			<div className="recording__close" onClick={props.onClose}>×</div>

			<div className="recording__inner">
				<RecordingDateTime recording={recording} country={true} />
				{recording.entryCredit && <div>by {recording.entryCredit}</div>}
				
				<div className="recording__imagesquestions">
					

					<div className="recording__questions">
						{recording.recorderWeatherDescription && (
							<div>
								<h4>Describe your experience of the weather while receiving the satellite transmission?</h4>
								<p>{recording.recorderWeatherDescription}</p>
							</div>
						)}
					
						{recording.recorderClimateChangeLocate && (
							<div className="recording__images">
								<h4>Where do you locate climate change in your local community or environment?</h4>
								<p>{recording.recorderClimateChangeLocate}</p>
							</div>
						)}
					</div>
					{additionalImages[recording.additionalImageFilename] && (
						<div className="recording__images">
							<img src={additionalImages[recording.additionalImageFilename].default} />
						</div>
					)}
				</div>

				{decodedImages[recording.decodedWAVFilename] && (
					<div className="decoded">
						<img src={decodedImages[recording.decodedWAVFilename].default} />
					</div>
				)}
			</div>

		</div>
	)
}

export default RecordingsDetails

